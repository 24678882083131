
    <template>
      <section class="content element-doc">
        <h2>Backtop 回到顶部</h2>
<p>返回页面顶部的操作按钮</p>
<h3>基础用法</h3>
<p>滑动页面即可看到右下方的按钮。</p>
<demo-block>
        
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  Scroll down to see the bottom-right button.
  &lt;el-backtop target=&quot;.page-component__scroll .el-scrollbar__wrap&quot;&gt;&lt;/el-backtop&gt;
&lt;/template&gt;
</code></pre></template></demo-block><h3>自定义显示内容</h3>
<p>显示区域被固定为 40px * 40px 的区域, 其中的内容可支持自定义。</p>
<demo-block>
        
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  Scroll down to see the bottom-right button.
  &lt;el-backtop
    target=&quot;.page-component__scroll .el-scrollbar__wrap&quot;
    :bottom=&quot;100&quot;
  &gt;
    &lt;div
      style=&quot;{
        height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color: #1989fa;
      }&quot;
    &gt;
      UP
    &lt;/div&gt;
  &lt;/el-backtop&gt;
&lt;/template&gt;
</code></pre></template></demo-block><h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>target</td>
<td>触发滚动的对象</td>
<td>string</td>
<td></td>
<td></td>
</tr>
<tr>
<td>visibility-height</td>
<td>滚动高度达到此参数值才出现</td>
<td>number</td>
<td></td>
<td>200</td>
</tr>
<tr>
<td>right</td>
<td>控制其显示位置, 距离页面右边距</td>
<td>number</td>
<td></td>
<td>40</td>
</tr>
<tr>
<td>bottom</td>
<td>控制其显示位置, 距离页面底部距离</td>
<td>number</td>
<td></td>
<td>40</td>
</tr>
</tbody>
</table>
<h3>Events</h3>
<table>
<thead>
<tr>
<th>事件名</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>click</td>
<td>点击按钮触发的事件</td>
<td>点击事件</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, createTextVNode: _createTextVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_backtop = _resolveComponent("el-backtop")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createTextVNode("Scroll down to see the bottom-right button. "),
    _createVNode(_component_el_backtop, { target: ".page-component__scroll .el-scrollbar__wrap" })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createTextVNode: _createTextVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_backtop = _resolveComponent("el-backtop")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createTextVNode("Scroll down to see the bottom-right button. "),
    _createVNode(_component_el_backtop, {
      target: ".page-component__scroll .el-scrollbar__wrap",
      bottom: 100
    }, {
      default: _withCtx(() => [
        _createVNode("div", { style: {"{\n        height":"100%","width":"100%","background-color":"#f2f5f6","box-shadow":"0 0 6px rgba(0,0,0, .12)","text-align":"center","line-height":"40px","color":"#1989fa"} }, " UP ")
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  